<template>
  <div id="app_nav" v-show="visible">
    <div class="app_link" exact>
      <router-link to="/home">
        <!-- <img class="img"
             src="../../assets/index1.png"
             alt="" /> -->
        <img
          class="img"
          :src="
            '/home' === $route.path
              ? tabBarImgArr[0].selected
              : tabBarImgArr[0].normal
          "
          alt="职业档案"
        />
        <p>首页</p>
      </router-link>
    </div>
    <div class="app_link" exact>
      <router-link to="/postList">
        <img
          class="img"
          :src="
            '/postList' === $route.path
              ? tabBarImgArr[3].selected
              : tabBarImgArr[3].normal
          "
          alt="大咖内推"
        />
        <p>大咖内推</p>
      </router-link>
    </div>
    <!-- <div class="app_link">
      <router-link to="/resumeassistant">

        <img
          class="img"
          :src="
            '/resumeassistant' === $route.path
              ? tabBarImgArr[1].selected
              : tabBarImgArr[1].normal
          "
          alt="职业档案"
        />
        <p>简历助手</p>
      </router-link>
    </div> -->
    <!-- <div class="app_link">
      <router-link to="/backtotwo">
        <span class="yuan">
          
          <img
            class="bei"
            :src="
              '/backtotwo' === $route.path
                ? tabBarImgArr[2].selected
                : tabBarImgArr[2].normal
            "
            alt="背调"
          />
          <p class="diao">背调</p>
        </span>
      </router-link>
    </div> -->
    <div class="app_link" exact>
      <router-link to="/backtotwo">
        <img
          class="img"
          :src="
            '/backtotwo' === $route.path
              ? tabBarImgArr[2].selected
              : tabBarImgArr[2].normal
          "
          alt="背调"
        />
        <p>背调</p>
      </router-link>
    </div>
    <div class="app_link" exact>
      <router-link to="/user">
        <!-- <img class="img"
             src="../../assets/user.png"
             alt="" /> -->
        <img
          class="img"
          :src="
            '/user' === $route.path
              ? tabBarImgArr[4].selected
              : tabBarImgArr[4].normal
          "
          alt="我的"
        />
        <p>我的</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
      tabBarImgArr: [
        //图片切换
        {
          normal: require("../../assets/index1.png"),
          selected: require("../../assets/index.png"),
        },
        {
          normal: require("../../assets/jilu.png"),
          selected: require("../../assets/jilu1.png"),
        },
        {
          normal: require("../../assets/biediao.png"),
          selected: require("../../assets/fangdajing.png"),
        },
        {
          normal: require("../../assets/jianli2.png"),
          selected: require("../../assets/dknt.png"),
        },
        {
          normal: require("../../assets/user.png"),
          selected: require("../../assets/user1.png"),
        },
      ],
    };
  },
  methods: {},
  watch: {
    $route: {
      handler(n) {
        console.log(n);
        this.visible = !n.meta.flag;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
#app_nav {
  width: 100%;
  z-index: 100;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 1.39rem;
  background-color: #fff;
}

#app_nav .app_link {
  flex: 1;
  text-align: center;
  font-size: 0.2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f44c06;
}
#app_nav .app_link .img {
  width: 0.44rem;
  height: 0.44rem;
}
#app_nav .app_link .yuan {
  background: url(../../assets/ellipse.png);
  display: block;
  width: 1.12rem;
  height: 1.12rem;
  background-size: 100%;
  position: fixed;
  bottom: 0.57rem;
  margin-left: 0.15rem;
}
#app_nav .app_link .yuan .bei {
  margin-top: 0.15rem;
  width: 0.44rem;
  height: 0.44rem;
}
#app_nav .app_link .yuan .diao {
  font-size: 0.2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
#app_nav .app_link p:hover {
  color: #f44c06;
}
#app_nav .app_link p {
  color: #616b80;
}
</style>
