var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],attrs:{"id":"app_nav"}},[_c('div',{staticClass:"app_link",attrs:{"exact":""}},[_c('router-link',{attrs:{"to":"/home"}},[_c('img',{staticClass:"img",attrs:{"src":'/home' === _vm.$route.path
            ? _vm.tabBarImgArr[0].selected
            : _vm.tabBarImgArr[0].normal,"alt":"职业档案"}}),_c('p',[_vm._v("首页")])])],1),_c('div',{staticClass:"app_link",attrs:{"exact":""}},[_c('router-link',{attrs:{"to":"/postList"}},[_c('img',{staticClass:"img",attrs:{"src":'/postList' === _vm.$route.path
            ? _vm.tabBarImgArr[3].selected
            : _vm.tabBarImgArr[3].normal,"alt":"大咖内推"}}),_c('p',[_vm._v("大咖内推")])])],1),_c('div',{staticClass:"app_link",attrs:{"exact":""}},[_c('router-link',{attrs:{"to":"/backtotwo"}},[_c('img',{staticClass:"img",attrs:{"src":'/backtotwo' === _vm.$route.path
            ? _vm.tabBarImgArr[2].selected
            : _vm.tabBarImgArr[2].normal,"alt":"背调"}}),_c('p',[_vm._v("背调")])])],1),_c('div',{staticClass:"app_link",attrs:{"exact":""}},[_c('router-link',{attrs:{"to":"/user"}},[_c('img',{staticClass:"img",attrs:{"src":'/user' === _vm.$route.path
            ? _vm.tabBarImgArr[4].selected
            : _vm.tabBarImgArr[4].normal,"alt":"我的"}}),_c('p',[_vm._v("我的")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }