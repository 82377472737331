<!--
 * @Author: your name
 * @Date: 2021-01-15 21:40:01
 * @LastEditTime: 2021-01-15 21:58:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\components\kefu\index.vue
-->
<template>
  <div>
    <van-popup class="qrcoedclass" v-model="showqrcode">
      <div>
        <img src="../../assets/图片1.png" />
      </div>
      <div>请长按联系客服</div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showqrcode: false,
    };
  },
  methods: {
    showmeth() {
      this.showqrcode = true;
    },
  },
};
</script>
<style scoped>
.qrcoedclass {
  width: 5.44rem;
  height: 4.2rem;
  border-radius: 0.2rem;
  font-size: 0.28rem;
}
.qrcoedclass img {
  width: 1.8rem;
  height: 1.8rem;
}
.qrcoedclass > div:first-child {
  text-align: center;
  margin-top: 0.89rem;
}
.qrcoedclass > div:nth-child(2) {
  text-align: center;
  margin-top: 0.23rem;
}
</style>
