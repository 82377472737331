import Vue from "vue";
import VueRouter from "vue-router";
import base from "../../util/base";
import eventBus from "../../util/eventbus"
import loginmeth from "../../util/logintime"

Vue.use(VueRouter);

const routes = [
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test/index.vue"),
    meta: {
      title: "test",
      flag: false
    }
  },
  {
    path: "/Login",
    name: "Login",
    component: (resolve) => require(["@/views/login/login.vue"], resolve),
    meta: {
      title: "登录",
      flag: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: (resolve) => require(["@/views/login/register.vue"], resolve),
    meta: {
      title: "注册",
      flag: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
    meta: {
      title: '萝卜猎手-首页',
    }

  },
  // {
  //   path: "/backto",
  //   name: "backto",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/backto/index.vue"),
  //   meta: {
  //     title: "发起背调",
  //     flag: true,
  //     keepAlive: true
  //   },
  // },
  {
    path: "/profession",
    name: "profession",
    component: () => import("../views/profession/index.vue"),
    meta: {
      title: "二维码管理",
      flag: true,
    },
  },
  {
    path: "/record",
    name: "record",
    component: () => import("../views/record/index.vue"),
    meta: {
      title: "背调记录",
      flag: true,
    },
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/index.vue"),
    meta: {
      title: "我的"
    }
  },
  {
    path: "/records",
    name: "records",
    component: () => import("../views/records/index.vue"),
    meta: {
      keepAlive: true // 需要被缓存
    }
  },
  {
    path: "/recharge",
    name: "recharge",
    component: () => import("../views/recharge/index.vue"),
    meta: {
      title: "充值",
      flag: true,
    },
  },
  {
    path: "/successpage",
    name: "successpage",
    component: () => import("../views/successpage/index.vue"),
    meta: {
      title: "成功",
      flag: true,
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/record/search/index.vue"),
    meta: {
      title: "搜索",
      flag: true,
    },
  },
  {
    path: "/seeportmsg",
    name: "seeportmsg",
    component: () => import("../views/seeportmsg/index.vue"),
    meta: {
      title: "查看报告",
      flag: true,
    },
  },
  {
    path: "/backtomsg",
    name: "backtomsg",
    component: () => import("../views/backtomsg/index.vue"),
    meta: {
      title: "查看背调",
      flag: true,
    },
  },
  {
    path: "/toback",
    name: "toback",
    component: () => import("../views/toback/index.vue"),
    meta: {
      title: "发起背调",
      flag: true,
    },
  },
  {
    path: "/backcenter",
    name: "backcenter",
    component: () => import("../views/backcenter/index.vue"),
    meta: {
      title: "授权中心",
      flag: true,
    },
  },
  {
    path: "/backcenter2",
    name: "backcenter2",
    component: () => import("../views/backcenter2/index.vue"),
    meta: {
      title: "授权中心(企业)",
      flag: true,
    },
  },
  {
    path: "/allowpay",
    name: "allowpay",
    component: () => import("../views/allowpay/index.vue"),
    meta: {
      title: "授权支付",
      flag: true,
    },
  },
  {
    path: "/beidiaosuccess",
    name: "beidiaosuccess",
    component: () => import("../views/beidiaosuccess/index.vue"),
    meta: {
      title: "背调支付成功",
      flag: true,
    },
  },
  {
    path: "/backinformation",
    name: "backinformation",
    component: () => import("../views/backinformation/index.vue"),
    meta: {
      title: "核实信息",
      flag: true,
    },
  },
  {
    path: "/authorizationsuccess",
    name: "authorizationsuccess",
    component: () => import("../views/authorizationsuccess/index.vue"),
    meta: {
      title: "授权成功",
      flag: true,
    },
  },
  {
    path: "/authorizationsuccesstwo",
    name: "authorizationsuccesstwo",
    component: () => import("../views/authorizationsuccesstwo/index.vue"),
    meta: {
      title: "授权成功2",
      flag: true,
    },
  },
  {
    path: "/modifypass",
    name: "modifypass",
    component: () => import("../views/modifypass/index.vue"),
    meta: {
      title: "修改密码",
      flag: true,
    },
  },
  {
    path: "/successpass",
    name: "successpass",
    component: () => import("../views/successpass/index.vue"),
    meta: {
      title: "修改成功",
      flag: true,
    },
  },
  {
    path: "/registersuccess",
    name: "registersuccess",
    component: () => import("../views/registersuccess/index.vue"),
    meta: {
      title: "修改成功",
      flag: true,
    },
  },
  {
    path: "/backrefuse",
    name: "backrefuse",
    component: () => import("../views/backrefuse/index.vue"),
    meta: {
      title: "拒绝授权",
      flag: true,
    },
  },
  {
    path: "/backsendsuccess",
    name: "backsendsuccess",
    component: () => import("../views/backsendsuccess/index.vue"),
    meta: {
      title: "授权发送成功",
      flag: true,
    },
  },
  {
    path: "/details",
    name: "details",
    component: () => import("../views/details/index.vue"),
    meta: {
      title: "充值记录",
      flag: true,
    },
  },
  {
    path: "/enterprise",
    name: "enterprise",
    component: () => import("../views/enterprise/index.vue"),
    meta: {
      title: "企业认证",
      flag: true,
    },
  },
  {
    path: "/topupdetail",
    name: "topupdetail",
    component: () => import("../views/topupdetail/index.vue"),
    meta: {
      title: "账户明细",
      flag: true,
    },
  },
  {
    path: "/qrcode",
    name: "qrcode",
    component: () => import("../views/qrcode/index.vue"),
    meta: {
      title: "预览二维码",
      flag: true,
    },
  },
  {
    path: "/invoice",
    name: "invoice",
    component: () => import("../views/invoice/index.vue"),
    meta: {
      title: "我的发票",
      flag: true,
    },
  },
  {
    path: "/submitsuccessfully",
    name: "submitsuccessfully",
    component: () => import("../views/submitsuccessfully/index.vue"),
    meta: {
      title: "提交成功",
      flag: true,
    },
  },
  {
    path: "/nofeature",
    name: "nofeature",
    component: () => import("../views/nofeature/index.vue"),
    meta: {
      title: "功能开发中",
      flag: true,
    },
  },
  {
    path: "/loadmore",
    name: "loadmore",
    component: () => import("../views/loadmore/index.vue"),
    meta: {
      title: "加载更多",
    },
  },
  {
    path: "/",
    name: "index",
    component: () => import("../views/index/index.vue"),
    meta: {
      title: "萝卜猎手公众号",
      flag: true
    },
  },
  {
    path: "/transit",
    name: "transit",
    component: () => import("../views/transit/index.vue"),
    meta: {
      title: "请稍后...",
      flag: true
    },
  },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("../views/protocol/index.vue"),
    meta: {
      title: "协议",
      flag: true
    },
  },
  {
    path: "/privacyprotocol",
    name: "privacyprotocol",
    component: () => import("../views/privacyprotocol/index.vue"),
    meta: {
      title: "隐私协议",
      flag: true
    },
  },
  {
    path: "/serviceprotocol",
    name: "serviceprotocol",
    component: () => import("../views/serviceprotocol/index.vue"),
    meta: {
      title: "服务协议",
      flag: true
    },
  },
  {
    path: "/authorizationprotocol",
    name: "authorizationprotocol",
    component: () => import("../views/authorizationprotocol/index.vue"),
    meta: {
      title: "授权协议",
      flag: true
    },
  },
  {
    path: "/htmlpay",
    name: "htmlpay",
    component: () => import("../views/htmlpay/index.vue"),
    meta: {
      title: "支付",
      flag: true
    },
  },
  {
    path: "/setup",
    name: "setup",
    component: () => import("../views/setup/index.vue"),
    meta: {
      title: "设置",
      flag: true
    },
  },
  {
    path: "/wxpay",
    name: "wxpay",
    component: () => import("../views/wxpay/index.vue"),
    meta: {
      title: "微信支付",
      flag: true
    },
  },
  {
    path: "/shareit",
    name: "shareit",
    component: () => import("../views/shareit/index.vue"),
    meta: {
      title: "邀请有礼",
      flag: true
    },
  },
  {
    path: "/applicationvoice",
    name: "applicationvoice",
    component: () => import("../views/applicationvoice/index.vue"),
    meta: {
      title: "申请开票",
      flag: true,
      keepAlive: true
    },
  },
  {
    path: "/voicedetails",
    name: "voicedetails",
    component: () => import("../views/voicedetails/index.vue"),
    meta: {
      title: "申请详情",
      flag: true
    },
  },
  {
    path: "/sharepage",
    name: "sharepage",
    component: () => import("../views/sharepage/index.vue"),
    meta: {
      title: "分享详情",
      flag: true
    },
  },
  {
    path: "/systeminformation",
    name: "systeminformation",
    component: () => import("../views/systeminformation/index.vue"),
    meta: {
      title: "站内信",
      flag: true
    },
  },
  {
    path: "/mustread",
    name: "mustread",
    component: () => import("../views/mustread/index.vue"),
    meta: {
      title: "必读",
      flag: true
    },
  },
  {
    path: "/guide",
    name: "guide",
    component: () => import("../views/guide/index.vue"),
    meta: {
      title: "推广指南",
      flag: true
    },
  },
  {
    path: "/shareit1",
    name: "shareit1",
    component: () => import("../views/shareit1/index.vue"),
    meta: {
      title: "邀请有礼海报",
      flag: true
    },
  },
  {
    path: "/home2",
    name: "home2",
    component: () => import("../views/home2/index.vue"),
    meta: {
      title: "测试",
      flag: true
    },
  },
  {
    path: "/postone",
    name: "postone",
    component: () => import("../views/postone/index.vue"),
    meta: {
      title: "初级岗位包",
      flag: true,
      keepAlive: true
    },
  },
  {
    path: "/posttwo",
    name: "posttwo",
    component: () => import("../views/posttwo/index.vue"),
    meta: {
      title: "中级岗位包",
      flag: true,
      keepAlive: true
    },
  },
  {
    path: "/postthree",
    name: "postthree",
    component: () => import("../views/postthree/index.vue"),
    meta: {
      title: "高级岗位包",
      flag: true,
      keepAlive: true
    },
  },


  {
    path: "/safeguard",
    name: "safeguard",
    component: () => import("../views/safeguard/index.vue"),
    meta: {
      title: "用工保障",
      flag: true
    },
  },

  {
    path: "/postfour",
    name: "postfour",
    component: () => import("../views/postfour/index.vue"),
    meta: {
      title: "自定义产品包",
      flag: true,
      keepAlive: true
    },
  },
  {
    path: "/collaborate",
    name: "collaborate",
    component: () => import("../views/collaborate/index.vue"),
    meta: {
      title: "合作伙伴",
      flag: true
    },
  },
  {
    path: "/makemoney",
    name: "makemoney",
    component: () => import("../views/makemoney/index.vue"),
    meta: {
      title: "轻松赚钱",
      flag: true
    },
  },
  {
    path: "/productcenter",
    name: "productcenter",
    component: () => import("../views/productcenter/index.vue"),
    meta: {
      title: "产品中心",
      flag: true
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/terms/index.vue"),
    meta: {
      title: "条款内容",
      flag: true
    },
  },
  {
    path: "/sample",
    name: "sample",
    component: () => import("../views/sample/index.vue"),
    meta: {
      title: "报告样例",
      flag: true
    },
  },
  {
    path: "/searchmsg",
    name: "searchmsg",
    component: () => import("../views/searchmsg/index.vue"),
    meta: {
      title: "背调查询结果",
      flag: true
    },
  },
  {
    path: "/reportview",
    name: "reportview",
    component: () => import("../views/reportview/index.vue"),
    meta: {
      title: "查看报告",
      flag: true
    },
  },
  {
    path: "/reportview2",
    name: "reportview2",
    component: () => import("../views/reportview2/index.vue"),
    meta: {
      title: "查看报告",
      flag: true
    },
  },
  {
    path: "/understand",
    name: "understand",
    component: () => import("../views/understand/index.vue"),
    meta: {
      title: "马上了解",
      flag: true
    },
  },
  {
    path: "/backtotwo",
    name: "backtotwo",
    component: () => import("../views/backtotwo/index.vue"),
    meta: {
      title: "选择产品包",
      flag: false,
      keepAlive: true
    },
  },
  {
    path: "/postfive",
    name: "postfive",
    component: () => import("../views/postfive/index.vue"),
    meta: {
      title: "产品包详情",
      flag: true
    },
  },
  {
    path: "/tobacktwo",
    name: "tobacktwo",
    component: () => import("../views/tobacktwo/index.vue"),
    meta: {
      title: "发起背调",
      flag: true
    },
  },
  {
    path: "/integral",
    name: "integral",
    component: () => import("../views/integral/index.vue"),
    meta: {
      title: "积分兑换",
      flag: true,
      keepAlive: true
    },
  },
  {
    path: "/integralok",
    name: "integralok",
    component: () => import("../views/integralok/index.vue"),
    meta: {
      title: "积分兑换确认",
      flag: true
    },
  },
  {
    path: "/resume",
    name: "resume",
    component: () => import("../views/resume/index.vue"),
    meta: {
      title: "简历公海",
      flag: true
    },
  },
  {
    path: "/sub",
    name: "sub",
    component: () => import("../views/sub/index.vue"),
    meta: {
      title: "简历详情",
      flag: true
    },
  },
  {
    path: "/shareresume",
    name: "shareresume",
    component: () => import("../views/shareresume/index.vue"),
    meta: {
      title: "简历分享",
      flag: true
    },
  },
  {
    path: "/shareresume2",
    name: "shareresume2",
    component: () => import("../views/shareresume2/index.vue"),
    meta: {
      title: "简历分享",
      flag: true
    },
  },
  {
    path: "/shareresume3",
    name: "shareresume3",
    component: () => import("../views/shareresume3/index.vue"),
    meta: {
      title: "简历分享",
      flag: true
    },
  },
  {
    path: "/dingyue",
    name: "dingyue",
    component: () => import("../views/dingyue/index.vue"),
    meta: {
      title: "订阅列表",
      flag: true
    },
  },
  {
    path: "/dingyuesetup",
    name: "dingyuesetup",
    component: () => import("../views/dingyuesetup/index.vue"),
    meta: {
      title: "订阅列表",
      flag: true
    },
  },
  {
    path: "/dingyuemsg",
    name: "dingyuemsg",
    component: () => import("../views/dingyuemsg/index.vue"),
    meta: {
      title: "订阅详情",
      flag: true
    },
  },
  {
    path: "/dingyuelist",
    name: "dingyuelist",
    component: () => import("../views/dingyuelist/index.vue"),
    meta: {
      title: "订阅列表",
      flag: true
    },
  },
  {
    path: "/dingyuelistmsg",
    name: "dingyuelistmsg",
    component: () => import("../views/dingyuelistmsg/index.vue"),
    meta: {
      title: "订阅岗位列表",
      flag: true
    },
  },
  {
    path: "/skilllist",
    name: "skilllist",
    component: () => import("../views/skilllist/index.vue"),
    meta: {
      title: "技能云",
      flag: true
    },
  },
  {
    path: "/skillmsg",
    name: "skillmsg",
    component: () => import("../views/skillmsg/index.vue"),
    meta: {
      title: "技能详情",
      flag: true
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile/index.vue"),
    meta: {
      title: "职业档案",
      flag: true
    },
  },
  {
    path: "/profilemsg",
    name: "profilemsg",
    component: () => import("../views/profilemsg/index.vue"),
    meta: {
      title: "职业档案",
      flag: true
    },
  },
  {
    path: "/evaluation",
    name: "evaluation",
    component: () => import("../views/evaluation/index.vue"),
    meta: {
      title: "我要评价",
      flag: true
    },
  },
  {
    path: "/evalsee",
    name: "evalsee",
    component: () => import("../views/evalsee/index.vue"),
    meta: {
      title: "查看回评",
      flag: true
    },
  },
  {
    path: "/evalpay",
    name: "evalpay",
    component: () => import("../views/evalpay/index.vue"),
    meta: {
      title: "支付查看评价",
      flag: true
    },
  },
  {
    path: "/resumesee",
    name: "resumesee",
    component: () => import("../views/resumesee/index.vue"),
    meta: {
      title: "查看简历",
      flag: true
    },
  },
  {
    path: "/addskill",
    name: "addskill",
    component: () => import("../views/addskill/index.vue"),
    meta: {
      title: "新增技能",
      flag: true,
      keepAlive: true
    },
  },
  {
    path: "/addskill2",
    name: "addskill2",
    component: () => import("../views/addskill2/index.vue"),
    meta: {
      title: "自定义技能",
      flag: true
    },
  },
  {
    path: "/addskill3",
    name: "addskill3",
    component: () => import("../views/addskill3/index.vue"),
    meta: {
      title: "新增技能",
      flag: true
    },
  },
  {
    path: "/addskill4",
    name: "addskill4",
    component: () => import("../views/addskill4/index.vue"),
    meta: {
      title: "新增技能",
      flag: true
    },
  },
  {
    path: "/addskillselect",
    name: "addskillselect",
    component: () => import("../views/addskillselect/index.vue"),
    meta: {
      title: "搜索技能",
      flag: true
    },
  },
  {
    path: "/resumedownload",
    name: "resumedownload",
    component: () => import("../views/resumedownload/index.vue"),
    meta: {
      title: "简历下载",
      flag: true
    },
  },
  {
    path: "/school",
    name: "school",
    component: () => import("../views/shareresume2/school.vue"),
    meta: {
      title: "添加教育经历",
      flag: true
    },
  },
  {
    path: "/school2",
    name: "school2",
    component: () => import("../views/shareresume2/school2.vue"),
    meta: {
      title: "添加教育经历",
      flag: true
    },
  },
  {
    path: "/sub2",
    name: "sub2",
    component: () => import("../views/sub2/index.vue"),
    meta: {
      title: "简历详情",
      flag: true
    },
  },
  {
    path: "/resumefavorites",
    name: "resumefavorites",
    component: () => import("../views/resumefavorites/index.vue"),
    meta: {
      title: "简历收藏",
      flag: true
    },
  },
  {
    path: "/stationletter",
    name: "stationletter",
    component: () => import("../views/stationletter/index.vue"),
    meta: {
      title: "站内信",
      flag: true
    },
  },
  {
    path: "/staffadd",
    name: "staffadd",
    component: () => import("../views/staffadd/index.vue"),
    meta: {
      title: "添加员工",
      flag: true
    },
  },
  {
    path: "/my-file",
    name: "myfile",
    component: () => import("../views/myfile/index.vue"),
    meta: {
      title: "添加员工",
      flag: true
    },
  },
  {
    path: "/downloadresume",
    name: "downloadresume",
    component: () => import("../views/downloadresume/index.vue"),
    meta: {
      title: "下载简历",
      flag: true
    },
  },
  {
    path: "/setupall",
    name: "setupall",
    component: () => import("../views/setupall/index.vue"),
    meta: {
      title: "设置",
      flag: true
    }
  },
  {
    path: "/accountmsg",
    name: "accountmsg",
    component: () => import("../views/accountmsg/index.vue"),
    meta: {
      title: "账户信息",
      flag: true
    }
  },
  {
    path: "/facecallback",
    name: "facecallback",
    component: () => import("../views/facecallback/index.vue"),
    meta: {
      title: "人脸识别成功",
      flag: true
    }
  },
  {
    path: "/facerejection",
    name: "facerejection",
    component: () => import("../views/facerejection/index.vue"),
    meta: {
      title: "人脸识别失败",
      flag: true
    }
  },
  {
    path: "/resumeEvaluation",
    name: "resumeEvaluation",
    component: () => import("../views/resumeEvaluation/index.vue"),
    meta: {
      title: "能力分析",
      flag: true
    }
  },
  {
    path: "/resumematch",
    name: "resumematch",
    component: () => import("../views/resumematch/index.vue"),
    meta: {
      title: "胜任力匹配",
      flag: true
    }
  },
  {
    path: "/resumeassistant",
    name: "resumeassistant",
    component: () => import("../views/resumeassistant/index.vue"),
    meta: {
      title: "简历助手",
      flag: false
    }
  },
  {
    path: "/facestart",
    name: "facestart",
    component: () => import("../views/facestart/index.vue"),
    meta: {
      title: "萝卜猎手人脸实名认证",
      flag: true
    }
  },
  {
    path: "/faceupload",
    name: "faceupload",
    component: () => import("../views/faceupload/index.vue"),
    meta: {
      title: "人脸验证",
      flag: true
    }
  },
  {
    path: "/fanwenzheng",
    name: "fanwenzheng",
    component: () => import("../views/jiansuo1/index.vue"),
    meta: {
      title: "岗位检索",
      flag: true
    }
  }, {
    path: "/personDetail",
    name: "fanwenzheng",
    component: () => import("../views/personDetail/index.vue"),
    meta: {
      title: "候选人信息",
      flag: false
    }
  }, {
    path: "/resumeDetails",
    name: "fanwenzheng",
    component: () => import("../views/personDetail/resumeDetails.vue"),
    meta: {
      title: "简历",
      flag: true
    }
  }, {
    path: "/personReport",
    name: "fanwenzheng",
    component: () => import("../views/personDetail/personReport.vue"),
    meta: {
      title: "人才分析报告",
      flag: true
    }
  }, {
    path: "/postList",
    name: "postList",
    component: () => import("../views/personDetail/postList.vue"),
    meta: {
      title: "悬赏招聘",
      // flag: true
    },
  }, {
    path: "/postDetail",
    name: "postDetail",
    component: () => import("../views/personDetail/postDetail.vue"),
    meta: {
      title: "岗位详情",
      flag: true
    },
  },
  {
    path: "/step1",
    name: "step1",
    component: () => import("../views/post/step1.vue"),
    meta: {
      title: "岗位发布",
      flag: true
    },
  },
  {
    path: "/step2",
    name: "step2",
    component: () => import("../views/post/step2.vue"),
    meta: {
      title: "岗位发布",
      flag: true
    },
  },
  {
    path: "/step3",
    name: "step3",
    component: () => import("../views/post/step3.vue"),
    meta: {
      title: "岗位发布",
      flag: true
    },
  },
  {
    path: "/step4",
    name: "step4",
    component: () => import("../views/post/step4.vue"),
    meta: {
      title: "岗位发布",
      flag: true
    },
  },
  {
    path: "/step5",
    name: "step5",
    component: () => import("../views/post/step5.vue"),
    meta: {
      title: "岗位发布",
      flag: true
    },
  },
  {
    path: "/step6",
    name: "step6",
    component: () => import("../views/post/step6.vue"),
    meta: {
      title: "岗位发布",
      flag: true
    },
  },
  {
    path: "/projectreport",
    name: "projectreport",
    component: () => import("../views/post/projectreport.vue"),
    meta: {
      title: "岗位发布",
      flag: true
    },
  },
  {
    path: "/myset",
    name: "myset",
    component: () => import("../views/myset/index.vue"),
    meta: {
      title: "岗位发布",
      flag: true
    },
  },{
    path: "/personDetail/searchResume",
    name: "searchResume",
    component: () => import("../views/personDetail/searchResume.vue"),
    meta: {
      title: "岗位发布",
      flag: true
    },
  },{
    path: "/personDetail/postAllDetail",
    name: "postAllDetail",
    component: () => import("../views/personDetail/postAllDetail.vue"),
    meta: {
      title: "岗位详情",
      flag: true
    },
  },{
    path: "/personDetail/myPost",
    name: "myPost",
    component: () => import("../views/personDetail/myPost.vue"),
    meta: {
      title: "岗位管理",
      flag: true
    },
  }
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.path == "/reportview2") {
      if (!sessionStorage.getItem("istop") == 1) {
        return { x: 0, y: 0 }
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(route => {
  // if(route.name=="Login"||route.name=="login"){
  //   if(loginmeth()){
  //     window.location = "/home";
  //   }
  //   return;
  // }
  if (sessionStorage.getItem("nopage") != 1) {
    sessionStorage.setItem("nopage", 0);
  }
  /**分享进入 */
  if (route.query.urlsharees != undefined && route.query.isshare == 1) {
    if (!sessionStorage.getItem("url")) {
      sessionStorage.setItem("url", route.query.urlsharees);
    }
    sessionStorage.setItem("sharemsg", JSON.stringify(route.query));
    // 注册时上传code使用
    sessionStorage.setItem("sharePhoneToRegister", route.query.share_phone);

    if (route.query.tourlmsg) {
      window.location = route.query.tourlmsg;
    } else {
      window.location = route.query.urlsharees;
    }
  } else {
    if (!sessionStorage.getItem("url")) {
      sessionStorage.setItem("url", route.path);
    }
  }
  // // 从路由的元信息中获取 title 属性
  if (route.meta.title) {
    document.title = route.meta.title;
    // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const hackIframe = document.createElement('iframe');
      hackIframe.style.display = 'none';
      hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
      document.body.appendChild(hackIframe);
      setTimeout(_ => {
        document.body.removeChild(hackIframe);
      }, 300)
    }
  }
});

// router.beforeEach((to, from, next) => {
//   
//   next()
// })

export default router;
