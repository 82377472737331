/**
 * 
 * @param {*} url 当前路由
 * @returns true:不展示 false：展示
 */
import islogin from "./islogin"
import isshowguide from "./isshowguide"
function guidemeth(url) {
    return new Promise((resolve, reject) => {
        if (!islogin()) return;
        if (!url) throw "url不能为空";
        let guidemsg = localStorage.getItem("guide_users");
        if (guidemsg) {
            // 是否是监听的url
            if (isshowguide.find(e => e == url)) {
                let arruser = JSON.parse(guidemsg);

                // 是否包含该手机号
                // 手机号下标（如果有）
                let phindex = arruser.findIndex(e => e.phone == localStorage.getItem("phone"));
                if (phindex != -1) {
                    // 获取下标内容(路由)
                    setTimeout(() => {
                        let indexmsg = arruser[phindex].url;
                        //是否包含该路由
                        if (indexmsg.find(e => e == url)) {
                            resolve(true);
                        } else {
                            indexmsg.push(url);
                            arruser[phindex].url = indexmsg;
                            localStorage.setItem("guide_users", JSON.stringify(arruser));
                            resolve(false);
                        }
                    });
                } else {
                    arruser.push({
                        phone: localStorage.getItem("phone"),
                        url: [url]
                    })
                    localStorage.setItem("guide_users", JSON.stringify(arruser));
                    resolve(false);
                }
            } else {
                resolve(true);
            }
        } else {
            let arr = [{
                phone: localStorage.getItem("phone"),
                url: []
            }];;
            localStorage.setItem("guide_users", JSON.stringify(arr));
            resolve(false);
        }
    })

}
export default guidemeth;
