<!--
 * @Author: your name
 * @Date: 2021-05-12 15:51:39
 * @LastEditTime: 2021-05-15 14:35:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\components\myalert\index.vue
-->
<template>
  <div>
    <div v-show="show == true" class="alertclass">
      <slot></slot>
      <div class="clsu">
        <div v-if="isone">
          <div @click="okmeth" class="su2">确认</div>
        </div>
        <div v-else>
          <div @click="nometh" class="cl">取消</div>
          <div @click="okmeth" class="su">确认</div>
        </div>
      </div>
    </div>
    <div v-show="show == true" class="mask"></div>
  </div>
</template>
<script>
export default {
  props: {
    show: Boolean,
    isone:Boolean,
  },
  methods: {
    okmeth() {
      this.$emit("okmeth");
    },
    nometh() {
      this.$emit("nometh");
    },
  },
};
</script>
<style scoped>
.clsu {
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 2200;
}
.clsu>div{
  display: flex;
  align-items: center;
  width: 100%;
}
.cl {
  background-color: #e8e8e8;
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: #333333;
  min-width: 50%;
  text-align: center;
  padding: 0.2rem 0;
}
.su {
  background-color: #ff4800;
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: #ffffff;
  min-width: 50%;
  text-align: center;
  padding: 0.2rem 0;
}
.su2{
  background-color: #ff4800;
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: #ffffff;
  min-width: 100%;
  text-align: center;
  padding: 0.2rem 0;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(29, 29, 29, 0.7);
  z-index: 1000;
}
.alertclass {
  width: 4.8rem;
  /* height: 5.61rem; */
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 0.16rem;
  overflow: hidden;
  z-index: 1200;
}
</style>
