<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <!-- <videocom></videocom> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive || keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive && !keepAlive"></router-view>
    <tabble />
    <!-- <div id="bbb">
      <div
        id="fff"
        v-show="aaa"
        @click="clickkefu"
        :class="iswen == false ? 'kefu6class' : 'kefu6class kefu2the'"
      >
        <img class="kefu6img" src="./assets/kefu6.png" alt="" />
        <div>
          <div>遇到问题了吗？</div>
          <div>点我联系客服！</div>
        </div>
      </div>
    </div> -->
    <!--
      是否进行企业认证
    -->
    <certificationreminder
      ref="shenid"
      v-on:no="no"
      v-on:ok="ok"
      :msg="msg"
      :ismsk="ismsk"
    ></certificationreminder>
    <kefu ref="kefuid"></kefu>
    <!--是否展示引导页-->
    <guide :showguideurl="showguideurl"></guide>
  </div>
</template>
<script>
import tabble from "../src/components/tabble/index";
import router from "./router";
import certificationreminder from "../src/components/certificationreminder/index";
import Vue from "vue";
import kefu from "./components/kefu/index";
import loginmeth from "../util/logintime";
import eventBus from "../util/eventbus";
import guide from "./components/guide/index.vue";
export default {
  name: "App",
  components: { tabble, certificationreminder, kefu, guide },
  data() {
    return {
      ismsk: false, //是否显示遮罩层弹窗1
      msg: "",
      iswen: "",
      aaa: false,
      keepAlive: false,
      showguideurl: "",
    };
  },
  created() {
    eventBus.$on("keepmeth", (msg) => {
      this.keepAlive = msg;
    });
    this.islogintime();
    var that = this;
    setTimeout(() => {
      that.aaa = true;
    }, 100);
    Vue.config.errorHandler = function(err, vm, info) {
      // that.iswen = true;
      // setTimeout(() => {
      //   that.iswen = false;
      // }, 10000);
    };
  },
  beforeCreate() {},
  methods: {
    /**
     * 验证登录是否过期
     */
    islogintime() {
      loginmeth();
    },
    clickkefu() {
      this.$refs.kefuid.showmeth();
    },
    test() {
      /**
       * 查詢企业审核状态
       */
      this.$http
        .post("/firm/v1/index/index1", {
          reqType: "resume",
        })
        .then((res) => {
          res = JSON.parse(res.data);
          if (res.data.status == 3) {
          } else if (res.data.status == 1) {
            this.ismsk = true;
            this.msg = "企业未认证";
          } else if (res.data.status == 4) {
            this.ismsk = true;
            this.msg = "审核未通过，是否进行企业认证";
          } else if (res.data.status == 2) {
            this.$refs.shenid.shenmeth();
            this.ismsk = true;
            this.msg = "企业审核中";
          }
        })
        .catch((err) => {});
    },
    ok(msg) {
      this.ismsk = false;
      if (msg == 2) {
      } else {
        this.$router.push("/enterprise");
      }
    },
    no() {
      this.ismsk = false;
    },
  },

  mounted() {
    console.log(
      "%c萝卜猎手%c快速识别简历真伪",
      "color:white;background:black;padding:1px 3px;",
      "color:white;background:#67C23A;padding:1px 3px;"
    );
    if ((window.location.host + "").indexOf("localhost") != -1) {
      console.log(
        "%c萝卜猎手文档地址%clocalhost/my-file",
        "color:black;padding:1px 3px;",
        "color:#67C23A;padding:1px 3px;"
      );
    }
    sessionStorage.removeItem("url");
    router.beforeEach((to, from, next) => {
      this.showguideurl = to.name;
      var _hmt = _hmt || [];
      _hmt.push([
        "_requirePlugin",
        "UrlChangeTracker",
        {
          shouldTrackUrlChange: function(newPath, oldPath) {
            return newPath && oldPath;
          },
        },
      ]);
      sessionStorage.setItem("nopage", 1);
      var that = this;
      if (
        to.fullPath == "/profession" ||
        to.fullPath == "/record" ||
        to.fullPath == "/tobacktwo" ||
        to.fullPath == "/invoice" ||
        to.fullPath == "/recharge" ||
        to.fullPath == "/topupdetail" ||
        to.fullPath == "/details" ||
        to.fullPath == "/resume" ||
        to.fullPath == "/profile" ||
        to.fullPath == "/resumedownload" ||
        to.fullPath == "/resumefavorites"
      ) {
        that.test();
      }
      next();
    });
  },
};
</script>

<style lang="scss">
@import "../src/assets/css/reset.css";
#fff {
  position: fixed;
  top: 45%;
  right: -5rem;
}
#bbb .kefu6class {
  position: fixed;
  top: 45%;
  right: -5rem;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0.01rem 0.1rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  box-shadow: 0px 0px 0.3rem rgba(73, 73, 73, 0.5);
  font-size: 0.25rem;
  color: #686868;
  transition: right 3s;
}
#bbb .kefu2the {
  right: 0;
}

.kefu6img {
  width: 1rem;
}
#app {
  height: 100%;
}
.marclass {
  height: 0.6rem;
}
html {
  height: 100%;
  background-color: #f6f6f7;
  font-family: PingFangSC-Medium, sans-serif;
}
/**
  所有单页页面的顶层 div 央视
 */
.allclass {
  background-color: #ffffff;
}
/*
  遮罩层
*/
.msk {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.mymenu {
  background-color: #ffffff;
  margin: 0.3rem;
  padding: 0.3rem;
  border-radius: 10px;
}
.mymenu div {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  justify-content: space-between;
  margin: 0.3rem 0;
}

body {
  font-size: 12px;
  width: 100%;
  height: 100%;
}

.bton {
  width: 90%;
  color: #ffffff;
  background: linear-gradient(to right, #f55613, #fc9e46);
  margin: 0 5%;
  border: none;
  border-radius: 0.8rem;
  font-size: 0.36rem;
  padding: 0.2rem;
  height: 0.96rem;
}
</style>
