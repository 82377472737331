/*
 * @Author: your name
 * @Date: 2020-12-03 16:28:17
 * @LastEditTime: 2021-04-29 21:52:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\util\httpes.js
 */
import instance from "./http";
import domain from "./base";
import QS from "qs";
import { Dialog } from "vant";
import { Toast } from "vant";
import { Notify } from "vant";

const get = (url, params) => {
    return new Promise((resolve, reject) => {
        instance
            .get(`${domain.formal}${url}`, {
                params: params,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
};

const post = (url, params, header) => {
    let parames = "";
    if (header) {
        parames = {
            accessToken: header.accessToken,
            user_id: header.userId,
            token: header.token,
            requestParams: params
        };
    } else {
        parames = {
            accessToken:
                localStorage.getItem("accessToken") != undefined
                    ? localStorage.getItem("accessToken")
                    : "",
            user_id:
                localStorage.getItem("userId") != undefined
                    ? localStorage.getItem("userId")
                    : "-1",
            token:
                localStorage.getItem("token") != undefined
                    ? localStorage.getItem("token")
                    : "",
            requestParams: params,
        };
    }
    return new Promise((resolve, reject) => {
        instance
            .post(`${domain.formal}${url}`, QS.stringify(parames))
            .then((res) => {
                if (JSON.parse(res.data).msg == "企业认证信息已存在") {
                    resolve(res);
                } else if (JSON.parse(res.data).code == "-9") {
                    Dialog.alert({
                        title: '提示',
                        message: JSON.parse(res.data).msg,
                    }).then(() => {
                        history.back();
                    });
                    return;
                }else if (JSON.parse(res.data).code != "0" &&JSON.parse(res.data).code != "-88"  && JSON.parse(res.data).code != "99" && JSON.parse(res.data).code != 8073) {
                    Toast.clear();
                    Dialog({ message: JSON.parse(res.data).msg });
                    return;
                }

                resolve(res);
            })
            .catch((err) => {

                // this.$toast(JSON.parse(err.data).msg)
                // .msg)
                reject(err);
            });
    });
};

export default {
    get,
    post,
};
