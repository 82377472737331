<!--
 * @Author: your name
 * @Date: 2021-05-18 13:56:05
 * @LastEditTime: 2021-05-19 18:21:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\components\other\name.vue
-->
<template>
  <div class="allmsgclass">
    <input
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      :class="classmsg"
      @focus="focus"
      @blur="blur"
      type="text"
      :placeholder="placeholder"
      :style="stylemsg"
    />
    <div class="tipclass">{{ tip }}</div>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    filter: String,
    ishaveto: {
      type: Boolean,
      default: false,
    }, //是否必填
    placeholder: String,
    stylemsg: String,
  },
  data() {
    return {
      classmsg: "",
      tip: "",
    };
  },
  methods: {
    focus() {
      this.classmsg = "focusclass";
      this.tip = "";
    },
    blur() {
      this.classmsg = "inclass";
      this.getfilter();
    },
    ishavetometh() {
      if (this.value == "" && this.ishaveto != true) {
        return false;
      }
    },
    getfilter() {
      switch (this.filter) {
        case "name":
          //姓名
          if (
            !/^[\u4e00-\u9fa5]+$/gi.test(this.value) ||
            (this.value + "").length < 1
          ) {
            if (this.ishavetometh() == false) return;
            this.$emit("input", "");
            this.tip = "格式不正确";
          }
          break;
        case "phone":
          //手机号
          if (!/^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/.test(this.value)) {
            if (this.ishavetometh() == false) return;
            this.$emit("input", "");
            this.tip = "格式不正确";
          }
          break;
        case "idcard":
          //身份证
          var reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
          if (!reg.test(this.value)) {
            if (this.ishavetometh() == false) return;
            this.$emit("input", "");
            this.tip = "格式不正确";
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.tipclass {
  color: #ff4800;
  font-size: 0.2rem;
}
.focusclass {
  border: 1px solid #409eff;
}
.inclass {
  border: 1px solid #dcdfe6;
}
input {
  border: 1px solid #dcdfe6;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
</style>
