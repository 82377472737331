/*
 * @Author: name
 * @Date: 2020-12-02 17:01:13
 * @LastEditTime: 2021-05-28 17:10:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings 1
 * @FilePath: \one\util\base.js
 */
/**
 * 接口域名的管理
 */
const base = {
    formal: 'https://luoboapiv1.udao.sh.cn', //接口地址
    test: 'https://luoboapiv1.udao.sh.cn', //接口地址
    url:'https://wx.luobo.udao.sh.cn',  //线上访问地址（非接口地址）
    tourlc:"http://client.luobo.udao.sh.cn", //跳转域名（萝卜猎手c端）
    tourl:"http://share.luobo.udao.sh.cn", //跳转域名（萝卜猎手官网）
    seximg1:"https://luoboapiv1.udao.sh.cn/upload/20210616/26e0d683e39f66071dd64b867c105727.png", //头像（男）
    seximg2:"https://luoboapiv1.udao.sh.cn/upload/20210616/0cf3cfe94318418ec035e3127e65ac7e.png", //头像（女）
    linkurl: "https://luoboapiv1.udao.sh.cn/upload/20210120/5beec401d087ff452618986efdcdd94b.png", //分享图标
    
    // formal: 'https://api.luobolieshou.com', //接口地址
    // test: 'https://api.luobolieshou.com', //接口地址
    // url: 'https://copm.luobolieshou.com',  //线上访问地址（非接口地址）
    // tourlc:"https://client.luobolieshou.com/", //跳转域名（萝卜猎手c端）
    // seximg1:"https://api.luobolieshou.com/upload/20210616/810a3d65843ff55ba126fc4f72c9bd91.png", //头像（男）
    // seximg2:"https://api.luobolieshou.com/upload/20210616/68af5fe6a600cf06b24981e791631db4.png", //头像（女）
    // linkurl:"https://api.luobolieshou.com/upload/20210521/cd6319614a36254d01138b8efd0a1fd8.png",
    // tourl:"https://share.luobolieshou.com", //跳转域名（萝卜官网）
}

export default base;
