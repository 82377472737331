/*
 * @Author: your name
 * @Date: 2020-12-22 18:37:48
 * @LastEditTime: 2021-05-31 15:02:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\util\paymeth.js
 */
import base from "../util/base"

async function paymeth(msg) {
    let wx = navigator.userAgent.toLowerCase();
    //判断运行环境
    //微信内置浏览器
    if (wx.match(/MicroMessenger/i) == "micromessenger") {
        let aaa = base.url+"/allowpay?";
        Object.keys(msg).forEach((key) => {
            aaa = aaa + key + "=" + msg[key] + "&"
        })
        aaa = aaa.substr(0, aaa.length - 1);
        window.location.href =
            base.formal+"/firm/v1/login/getCode2?url=" +
            encodeURIComponent(aaa);
    } else {
        //其他外部浏览器
        this.$router.push({
            path: "htmlpay",
            query: msg
        })
    }
};


export default paymeth;