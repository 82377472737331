<!--
 * @Author: your name
 * @Date: 2021-04-14 16:15:07
 * @LastEditTime: 2021-04-30 10:33:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\components\navigation\index.vue
-->
<template>
  <div>
    <van-sticky>
      <div :style="stylemsg" class="nac">
        <div>
          <van-icon
            v-if="noleft != true"
            @click="clickBack"
            size=".35rem"
            name="arrow-left"
          />
          <div
            v-if="customizeLeft == true && noleft == true"
            @click="$router.push('/home')"
            class="menuclasses"
          >
            <img class="iconclass" src="../../assets/homeicon.png" />
            <div class="tohomeclass">返回首页</div>
          </div>
        </div>

        <div class="xxm">{{ msg }}</div>
        <van-icon v-if="right" size=".35rem" name="arrow" />
        <slot></slot>
      </div>
    </van-sticky>
  </div>
</template>
<script>
export default {
  props: {
    right: Boolean,
    msg: String,
    noleft: Boolean,
    stylemsg: String,
    customizeLeft: {
      type: Boolean,
      value: false,
    },
  },
  methods: {
    clickBack() {
      if (sessionStorage.getItem("nopage")==0) {
        this.$router.push("/home");
      } else {
        this.$router.back(-1);
      }
    },
  },
};
</script>
<style scoped>
.menuclasses {
  display: flex;
  align-items: center;
}
.iconclass {
  width: 0.35rem;
  margin-right: 0.1rem;
  margin-top: -0.08rem;
}
.tohomeclass {
  color: #f9814f;
}
.xxm {
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.nac > :first-child {
  margin-left: 0.32rem;
}

.nac > :last-child {
  margin-right: 0.32rem;
}

.nac {
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  z-index: 1000;
  position: relative;
  /* border-bottom-color: #ebedf0;
        border-bottom-style: solid;
        border-bottom-width: 1px; */
}
</style>
