/*
 * @Author: your name
 * @Date: 2021-05-08 14:59:53
 * @LastEditTime: 2021-05-08 15:00:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\util\eventbus.js
 */
import Vue from 'vue'

export default new Vue()
