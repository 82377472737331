<template>
  <div>
    <div v-if="showurl == 'home' && home1" class="allmsgclass">
      <img
        class="theimg"
        :style="
          'width:' +
          maxwidth +
          'px;height:' +
          getXYMsg(homesize.x, homesize.y).y +
          'px;'
        "
        src="../../assets/guide_home.png"
        alt=""
      />
      <img
        :style="
          'height:' +
          (maxheight - getXYMsg(homesize.x, homesize.y).y) +
          'px;top:' +
          getXYMsg(homesize.x, homesize.y).y +
          'px;'
        "
        class="guideallclass"
        src="../../assets/guide_all.png"
        alt=""
      />
      <div
        v-if="home1"
        @click="
          home1 = false;
          home2 = true;
        "
        class="okboclass"
      >
        我知道啦
      </div>
    </div>
    <div v-if="showurl == 'home' && home2" class="allmsgclass">
      <img
        :style="
          'height:' +
          (maxheight - getXYMsg(homesize2.x, homesize2.y).y) +
          'px;top:0px;'
        "
        class="guideallclass"
        src="../../assets/guide_all.png"
        alt=""
      />
      <img
        @click="showurl = ''"
        class="theimg2"
        :style="
          'width:' +
          maxwidth +
          'px;height:' +
          getXYMsg(homesize2.x, homesize2.y).y +
          'px;'
        "
        src="../../assets/guide_home2.png"
        alt=""
      />

      <div @click="showurl = ''" class="okboclass2">开始发起第一个背调</div>
    </div>

    <div v-if="showurl == 'backtotwo' && backtotwo1" class="allmsgclass">
      <img
        class="theimg"
        :style="
          'width:' +
          maxwidth +
          'px;height:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        src="../../assets/guide_backtotwo.png"
        alt=""
      />
      <img
        :style="
          'height:' +
          (maxheight - getXYMsg(allsize.x, allsize.y).y) +
          'px;top:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        class="guideallclass"
        src="../../assets/guide_all.png"
        alt=""
      />
      <div
        @click="
          backtotwo1 = false;
          backtotwo2 = true;
        "
        class="okboclass3"
      >
        下一步
      </div>
    </div>
    <div
      @click="showurl = ''"
      v-if="showurl == 'backtotwo' && backtotwo2"
      class="allmsgclass"
    >
      <img
        class="theimg"
        :style="
          'width:' +
          maxwidth +
          'px;height:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        src="../../assets/guide_backtotwo2.png"
        alt=""
      />
      <img
        :style="
          'height:' +
          (maxheight - getXYMsg(allsize.x, allsize.y).y) +
          'px;top:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        class="guideallclass"
        src="../../assets/guide_all.png"
        alt=""
      />
      <img class="shouclass" src="../../assets/shou.png" alt="" />
      <div @click="showurl = ''" class="okboclass5">
        <img src="../../assets/xxx.png" alt="" />
      </div>
    </div>

    <div v-if="showurl == 'resume' && resume1" class="allmsgclass">
      <img
        class="theimg"
        :style="
          'width:' +
          maxwidth +
          'px;height:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        src="../../assets/guide_resume1.png"
        alt=""
      />
      <img
        :style="
          'height:' +
          (maxheight - getXYMsg(allsize.x, allsize.y).y) +
          'px;top:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        class="guideallclass"
        src="../../assets/guide_all.png"
        alt=""
      />
      <div
        @click="
          resume1 = false;
          resume2 = true;
        "
        class="okboclass3"
      >
        下一步
      </div>
    </div>
    <div v-if="showurl == 'resume' && resume2" class="allmsgclass">
      <img
        class="theimg"
        :style="
          'width:' +
          maxwidth +
          'px;height:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        src="../../assets/guide_resume2.png"
        alt=""
      />
      <img
        :style="
          'height:' +
          (maxheight - getXYMsg(allsize.x, allsize.y).y) +
          'px;top:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        class="guideallclass"
        src="../../assets/guide_all.png"
        alt=""
      />
      <div
        @click="
          resume2 = false;
          resume3 = true;
        "
        class="okboclass3"
      >
        下一步
      </div>
    </div>
    <div v-if="showurl == 'resume' && resume3" class="allmsgclass">
      <img
        class="theimg"
        :style="
          'width:' +
          maxwidth +
          'px;height:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        src="../../assets/guide_resume3.png"
        alt=""
      />
      <img
        :style="
          'height:' +
          (maxheight - getXYMsg(allsize.x, allsize.y).y) +
          'px;top:' +
          getXYMsg(allsize.x, allsize.y).y +
          'px;'
        "
        class="guideallclass"
        src="../../assets/guide_all.png"
        alt=""
      />
      <div @click="showurl = ''" class="okboclass3">下一步</div>
    </div>
  </div>
</template>
<script>
import isguide from "../../../util/isguide";
import eventbus from "../../../util/eventbus";
export default {
  data() {
    return {
      urlArray: ["home", "backtotwo", "resume"],
      maxwidth: window.innerWidth,
      maxheight: window.innerHeight,
      homesize: {
        x: 750,
        y: 1272,
      },
      homesize2: {
        x: 750,
        y: 1272,
      },
      allsize: {
        x: 750,
        y: 1272,
      },
      showurl: "",
      home1: true,
      home2: false,
      backtotwo1: true,
      backtotwo2: false,
      resume1: true,
      resume2: false,
      resume3: false,
    };
  },
  props: {
    showguideurl: String,
  },
  watch: {
    showguideurl(msg) {
      isguide(msg).then((res) => {
        if (!res) {
          if (msg == "backtotwo") {
            eventbus.$emit("zhankai");
          }
          this.showurl = msg;
        }
      });
    },
  },
  created() {
    eventbus.$on("guievent", (e) => {
      this.home1 = true;
      this.home2 = false;
      this.backtotwo1 = true;
      this.backtotwo2 = false;
      this.resume1 = true;
      this.resume2 = false;
      this.resume3 = false;
    });
  },
  methods: {
    /**
     * 获取宽高比
     */
    xymeth(x, y) {
      return y / x;
    },

    /**
     * 获取宽高转换后的长度
     */
    getXYMsg(x, y) {
      let winwidth = window.innerWidth;
      return {
        x: winwidth,
        y: winwidth * this.xymeth(x, y),
      };
    },
  },
  destroyed() {
    this.home1 = true;
    this.home2 = false;
  },
};
</script>
<style scoped>
.shouclass {
  position: fixed;
  top: 8.5rem;
  right: 1.08rem;
  z-index: 3001;
  width: 0.76rem;
  height: 0.92rem;
  animation: shouan 0.8s infinite alternate;
}

@keyframes shouan {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.guideallclass {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3000;
}
.allmsgclass {
}
.theimg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  width: 100%;
  height: 100%;
}
.theimg2 {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3000;
  width: 100%;
  height: 100%;
}

.okboclass,
.okboclass2,
.okboclass3 {
  position: fixed;
  top: 3.8rem;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  z-index: 3001;
  font-size: 0.28rem;
  font-weight: 400;
  color: #f6f6f7;
  line-height: 0.4rem;
  padding: 0.12rem 0.38rem;
  border: 0.02rem solid #f6f6f7;
  border-radius: 0.33rem;
}
.okboclass2 {
  top: auto;
  bottom: 4.5rem;
}
.okboclass3 {
  top: 5.5rem;
}
.okboclass5 {
  position: fixed;
  top: 5.3rem;
  right: 0.24rem;
  color: #ffffff;
  z-index: 3001;
}
.okboclass5 img {
  width: 0.52rem;
  height: 0.52rem;
}
</style>