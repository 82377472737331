/*
 * @Author: your name
 * @Date: 2020-12-01 13:21:02
 * @LastEditTime: 2021-05-18 17:26:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css'
import router from './router';
import store from './store';
import http from '../util/httpes'
import { Dialog } from 'vant';
import axios from "axios"
import VueWechatTitle from 'vue-wechat-title'
import observed from "../util/observed"
import paymeth from "../util/paymeth"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import navigation from "./components/navigation/index"
import myalert from "./components/myalert/index"
import myname from "./components/other/myname"
import mytextarea from './components/other/mytextarea'


Vue.use(ElementUI);
Vue.use(Vant);
// 全局注册
Vue.use(Dialog);

//路由标题
Vue.use(VueWechatTitle);

Vue.use(navigation);
Vue.use(myalert);
Vue.component("navigation",navigation);
Vue.component("myalert",myalert);
Vue.component("myname",myname);
Vue.component("mytextarea",mytextarea);

// Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$axios = axios;
Vue.prototype.$publish = observed;
Vue.prototype.$paymeth = paymeth;

new Vue({
  router,
  store,
  render: h => h(App),
  components:{
    navigation
  }
}).$mount('#app')


