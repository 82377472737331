/*
 * @Author: your name
 * @Date: 2021-04-07 11:43:21
 * @LastEditTime: 2021-04-07 11:45:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\util\logintime.js
 */
import base from "./base"
window.loginindex = 0
const loginmeth = () => {
    window.loginindex++;

    if (
        localStorage.getItem("loginTime")
    ) {
        let thetime = localStorage.getItem("loginTime");
        let b = Date.parse(new Date()) - thetime;
        let c = b / 1000 / 60 / 60;
        if (c > 8 || c < 0) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("token");
            localStorage.removeItem("loginTime");
            window.loginindex = 0;
            return false;
        } else {
            if (window.loginindex == 3) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userId");
                localStorage.removeItem("token");
                localStorage.removeItem("loginTime");
            }
            setTimeout(() => {
                window.loginindex = 0;
            }, 1000);
            return true;
        }
    } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        localStorage.removeItem("loginTime");
    }

}
export default loginmeth;
