var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showurl == 'home' && _vm.home1)?_c('div',{staticClass:"allmsgclass"},[_c('img',{staticClass:"theimg",style:('width:' +
        _vm.maxwidth +
        'px;height:' +
        _vm.getXYMsg(_vm.homesize.x, _vm.homesize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_home.png"),"alt":""}}),_c('img',{staticClass:"guideallclass",style:('height:' +
        (_vm.maxheight - _vm.getXYMsg(_vm.homesize.x, _vm.homesize.y).y) +
        'px;top:' +
        _vm.getXYMsg(_vm.homesize.x, _vm.homesize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_all.png"),"alt":""}}),(_vm.home1)?_c('div',{staticClass:"okboclass",on:{"click":function($event){_vm.home1 = false;
        _vm.home2 = true;}}},[_vm._v(" 我知道啦 ")]):_vm._e()]):_vm._e(),(_vm.showurl == 'home' && _vm.home2)?_c('div',{staticClass:"allmsgclass"},[_c('img',{staticClass:"guideallclass",style:('height:' +
        (_vm.maxheight - _vm.getXYMsg(_vm.homesize2.x, _vm.homesize2.y).y) +
        'px;top:0px;'),attrs:{"src":require("../../assets/guide_all.png"),"alt":""}}),_c('img',{staticClass:"theimg2",style:('width:' +
        _vm.maxwidth +
        'px;height:' +
        _vm.getXYMsg(_vm.homesize2.x, _vm.homesize2.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_home2.png"),"alt":""},on:{"click":function($event){_vm.showurl = ''}}}),_c('div',{staticClass:"okboclass2",on:{"click":function($event){_vm.showurl = ''}}},[_vm._v("开始发起第一个背调")])]):_vm._e(),(_vm.showurl == 'backtotwo' && _vm.backtotwo1)?_c('div',{staticClass:"allmsgclass"},[_c('img',{staticClass:"theimg",style:('width:' +
        _vm.maxwidth +
        'px;height:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_backtotwo.png"),"alt":""}}),_c('img',{staticClass:"guideallclass",style:('height:' +
        (_vm.maxheight - _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y) +
        'px;top:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_all.png"),"alt":""}}),_c('div',{staticClass:"okboclass3",on:{"click":function($event){_vm.backtotwo1 = false;
        _vm.backtotwo2 = true;}}},[_vm._v(" 下一步 ")])]):_vm._e(),(_vm.showurl == 'backtotwo' && _vm.backtotwo2)?_c('div',{staticClass:"allmsgclass",on:{"click":function($event){_vm.showurl = ''}}},[_c('img',{staticClass:"theimg",style:('width:' +
        _vm.maxwidth +
        'px;height:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_backtotwo2.png"),"alt":""}}),_c('img',{staticClass:"guideallclass",style:('height:' +
        (_vm.maxheight - _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y) +
        'px;top:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_all.png"),"alt":""}}),_c('img',{staticClass:"shouclass",attrs:{"src":require("../../assets/shou.png"),"alt":""}}),_c('div',{staticClass:"okboclass5",on:{"click":function($event){_vm.showurl = ''}}},[_c('img',{attrs:{"src":require("../../assets/xxx.png"),"alt":""}})])]):_vm._e(),(_vm.showurl == 'resume' && _vm.resume1)?_c('div',{staticClass:"allmsgclass"},[_c('img',{staticClass:"theimg",style:('width:' +
        _vm.maxwidth +
        'px;height:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_resume1.png"),"alt":""}}),_c('img',{staticClass:"guideallclass",style:('height:' +
        (_vm.maxheight - _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y) +
        'px;top:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_all.png"),"alt":""}}),_c('div',{staticClass:"okboclass3",on:{"click":function($event){_vm.resume1 = false;
        _vm.resume2 = true;}}},[_vm._v(" 下一步 ")])]):_vm._e(),(_vm.showurl == 'resume' && _vm.resume2)?_c('div',{staticClass:"allmsgclass"},[_c('img',{staticClass:"theimg",style:('width:' +
        _vm.maxwidth +
        'px;height:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_resume2.png"),"alt":""}}),_c('img',{staticClass:"guideallclass",style:('height:' +
        (_vm.maxheight - _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y) +
        'px;top:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_all.png"),"alt":""}}),_c('div',{staticClass:"okboclass3",on:{"click":function($event){_vm.resume2 = false;
        _vm.resume3 = true;}}},[_vm._v(" 下一步 ")])]):_vm._e(),(_vm.showurl == 'resume' && _vm.resume3)?_c('div',{staticClass:"allmsgclass"},[_c('img',{staticClass:"theimg",style:('width:' +
        _vm.maxwidth +
        'px;height:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_resume3.png"),"alt":""}}),_c('img',{staticClass:"guideallclass",style:('height:' +
        (_vm.maxheight - _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y) +
        'px;top:' +
        _vm.getXYMsg(_vm.allsize.x, _vm.allsize.y).y +
        'px;'),attrs:{"src":require("../../assets/guide_all.png"),"alt":""}}),_c('div',{staticClass:"okboclass3",on:{"click":function($event){_vm.showurl = ''}}},[_vm._v("下一步")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }