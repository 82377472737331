import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    form: '',
    poststatus: 'add', // 1.add 添加 2.edit 编辑 2. pay待支付
    educList:[],
    sexList:[],
    educVal:"",
    sexVal:"",
    postId:"",
  },
  mutations: {
    SET_POST_ID(state, data){
      state.postId = data;
    },
    SET_POST(state, data) {
      state.form = data;
    },
    SET_POST_STATUS(state, data) {
      state.poststatus = data;
    },
    SET_EDUC_LIST(state, data){
      state.educList = data;
    },
    SET_SEX_LIST(state, data){
      state.sexList = data;
    },
    SET_EDUC_VAL(state, data){
      state.educVal = data;
    },
    SET_SEX_VAL(state, data){
      state.sexVal = data;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
