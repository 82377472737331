<template>
  <div>
    <div v-wechat-title="$route.meta.title">
      <div  v-if="ismsk" class="msk"></div>
      <div v-if="ismsk" :style="shenhe == 1?'background-color:transparent;':''" class="popups">
        <div v-show="shenhe != 1">
          <div></div>
          <!-- <div>
            <img @click="ismsk = false" src="../../assets/delimg.png" />
          </div> -->
        </div>
        <!-- <p class="qrp">请完成企业认证，使用产品功能</p> -->
        <div v-show="shenhe != 1" class="qrimgparent">
          <img class="qrimg" src="../../assets/rz.png" />
        </div>
        <p @click="ok" v-show="shenhe == 1" id="qrp3">
          <img class="alertimg" src="../../assets/alertrz.png">
          <img  class="rzclcla" src="../../assets/rzclose.png">
        </p>
        <p v-show="shenhe == 2" class="qrp2">
          企业审核中......(如有疑问，请点击首页左上角联系客服。)
        </p>
        <div v-show="shenhe!=1">
          <div v-if="msg == undefined">
            <button @click="cliok" id="btqone">{{ okmsg }}</button>
            <button @click="clino" id="btqtwo">{{ nomsg }}</button>
          </div>

          <div v-else>
            <button @click="ok" id="btqone">{{ okmsg }}</button>
            <button @click="no" id="btqtwo">{{ nomsg }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      okmsg: "快速认证",
      nomsg: "取消",
      shenhe: 1,
    };
  },
  props: {
    ismsk: Boolean, //是否显示遮罩层
    msg: String, //页面传来的值
  },
  methods: {
    /**
     * 审核中
     */
    shenmeth() {
      this.shenhe = 2;
      this.okmsg = "确认";
    },
    cliok() {
      if (this.okmsg == "残忍拒绝") {
        this.$emit("ok", "");
      }
      this.okmsg = "残忍拒绝";
      this.nomsg = "同意授权";
    },
    clino() {
      this.$emit("modifyismsk", this.ismsk);
    },
    ok() {
      if (this.shenhe == 2) {
        this.$emit("ok", "2");
      } else {
        this.$emit("ok", "");
      }
    },
    no() {
      this.$emit("no", "");
    },
  },
};
</script>
<style scoped>
.rzclcla{
  position: absolute;
  top: 0;
  right: 0;
  width: .7rem;
}
#qrp3{
  margin: 0;
}
.alertimg{
  width: 100%;
}
.popups {
  z-index: 1100;
  position: fixed;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 10px;
  overflow: hidden;
  width: 75%;
}
.qrimgparent {
  text-align: center;
  position: relative;
}
.qrimg {
  width: 1.97rem;
}
.popups .qrp2 {
  font-size: 0.34rem;
  line-height: 0.68rem;
  font-weight: bold;
  margin-top: 0.42rem;
}
.popups .qrp {
  font-size: 0.34rem;
  line-height: 0.68rem;
  font-weight: bold;
  white-space: nowrap;
  margin: 0.38rem 0.31rem;
}
.popups p {
  margin: 0 0.47rem;
  min-width: 3.05rem;
  margin-bottom: 0.64rem;
  text-align: center;
  font-size: 0.3rem;
  color: #000000;
}
.popups > div:first-child {
  display: flex;
  justify-content: space-between;
  height: 0.75rem;
}
.popups > div:first-child img {
  width: 0.34rem;
}
#btqone,
#btqtwo {
  width: 50%;
  margin: 0;
  border: none;
  padding: 0.26rem 0;
  font-size: 0.32rem;
  line-height: 0.44rem;
}
#btqone {
  background-color: #f65a16;
  color: #ffffff;
}
#btqtwo {
  background-color: #dcdcdc;
}
</style>