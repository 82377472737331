<!--
 * @Author: your name
 * @Date: 2021-05-18 14:56:28
 * @LastEditTime: 2021-05-19 11:50:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\components\other\mytextarea.vue
-->
<template>
  <div>
    <textarea
      v-model="msg"
      @input="change"
      name=""
      id="autoresizing"
      class='autoresizing'
      :style="stylemsg"
      :readonly="readonlymsg"
    ></textarea>
  </div>
</template>
<script>
export default {
  props: {
    msg: String,
    stylemsg: String,
    readonlymsg: Boolean,
    index:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      a:1,
    }
  },
  watch: {
    msg() {
      this.init();
      this.change();
    },
  },
  data() {
    return {
      textarea: null,
    };
  },
  mounted() {
    this.init();
    this.change();
  },
  methods: {
    init() {
      this.textarea = document.getElementsByClassName("autoresizing")[this.index];
      this.textarea.style.width = "100%";
    },
    change() {
      this.textarea.style.height = "auto";
      this.textarea.style.height = this.textarea.scrollHeight + "px";
    },
  },
};
</script>
<style scoped>
</style>